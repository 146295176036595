



























































import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from '../plugins/moment'

import { Schedule, ScheduleStatus, Configuration } from '@/models'
import ApiService from '../api'
import { dateToText } from '@/lib/i18n'
import { AxiosError } from 'axios'

@Component({
  computed: {
    ...mapState(['ready', 'firebaseToken', 'config']),
    ...mapGetters(['logged', 'translate'])
  },

  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setSucessAlert', 'setErrorAlert', 'checkNeedLogin'])
  }
})
export default class SchedulesComponent extends Vue {
  config!: Configuration
  schedules: Schedule[] = []
  loaded = false

  ready!: boolean
  logged!: boolean
  firebaseToken!: string
  translate!: (key: string) => string
  showLoading!: () => void
  hideLoading!: () => void
  setSucessAlert!: (arg1: string) => void
  setErrorAlert!: (arg1: string) => void
  checkNeedLogin!: (arg1:boolean) => void

  get hasLogin () : boolean {
    if (this.logged) {
      this.load()
      return true
    } else {
      this.checkNeedLogin(true)
      return false
    }
  }

  get showNoneMessage () : boolean {
    return this.ready && this.loaded && this.schedules.length === 0
  }

  itemColor (status: ScheduleStatus, suffix = '') : string {
    const color = (status === ScheduleStatus.open) ? 'secondary' : 'primary'
    return color + suffix
  }

  title (schedule: Schedule) : string {
    return dateToText(moment(schedule.date).toDate())
  }

  load () : void {
    this.showLoading()
    ApiService.getSchedules().then((s: Schedule[]) => {
      this.schedules = s
      this.loaded = true
      this.hideLoading()
    })
  }

  cancel (id: number) : void {
    ApiService.cancelSchedule(id)
      .then(() => this.setSucessAlert(this.translate('screens.schedule.cancel')))
      .catch((e: AxiosError) => this.setErrorAlert(`${this.translate('screens.schedule.cancelError')}.<br>${e.message}`))
      .finally(this.load)
  }
}
