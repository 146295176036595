import { Entity } from './commom/entity'

export class Customer extends Entity {
  email = ''
  phone = ''
  birthdate = ''
  /* eslint-disable camelcase */
  birthdate_text_iso = ''
  /* eslint-enable camelcase */
  password = ''
}
